/* eslint-disable deprecation/deprecation */
/* eslint-disable no-useless-escape */

'use strict'
let HybridMessageModule
;(function (HybridMessageModule) {
  /**
   *
   *
   * @class HybridMessage
   */
  class HybridMessage {
    /**
     *
     *
     * @static
     * @param {string} name
     * @param {any} body
     * @param {string} webAppID
     * @param {Function} callback
     * @memberof HybridMessage
     */
    static sendMessageToAppCallback(name, body, webAppID, callback) {
      const message = new Message(name, body, webAppID)
      if (AppChecker.isIOSApp()) {
        const promise = window.webkit.messageHandlers.didReceiveMessageCallback.postMessage(
          JSON.stringify(message),
        )
        promise
          .then((json) => {
            callback(null, JSON.parse(json))
          })
          .catch((error) => {
            callback(Object.assign(Error, JSON.parse(error)), null)
          })
      } else if (AppChecker.isAndroidApp()) {
        window.Android.didReceiveMessageCallback(
          JSON.stringify(message),
          '(' + callback.toString() + ')',
        )
      }
    }

    /**
     *
     *
     * @static
     * @param {string} name
     * @param {any} body
     * @param {string} webAppID
     * @memberof HybridMessage
     */
    static sendMessageToApp(name, body, webAppID) {
      const message = new Message(name, body, webAppID)
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.didReceiveMessage.postMessage(JSON.stringify(message))
      } else if (AppChecker.isAndroidApp()) {
        window.Android.didReceiveMessage(JSON.stringify(message))
      }
    }

    /**
     *
     *
     * @static
     * @description Sends a custom message to the web
     * @param {string} json
     * @memberof HybridMessage
     */
    static sendMessageToWeb(json) {
      const didReceiveMessage = new CustomEvent(HybridMessage.Event.DidReceiveMessage, {
        detail: JSON.parse(HybridMessage.replaceEscapes(json)),
      })
      dispatchEvent(didReceiveMessage)
    }

    /**
     *
     *
     * @static
     * @description Sends a custom message to the web
     * @param {string} json
     * @memberof HybridMessage
     */
    static sendMessageToWebFailed(json) {
      const error = Object.assign(Error, JSON.parse(json))
      dispatchEvent(new CustomEvent(HybridMessage.Event.SendMessageToAppFailed, { detail: error }))
    }

    /**
     *
     *
     * @static
     * @param {string} name
     * @param {string} [webAppID=""]
     * @param {string} [applicationName=""]
     * @param {string} [entityNumber=""]
     * @param {string} [contractNumber=""]
     * @param {string} [userGuid=""]
     * @param {string} [customerGuid=""]
     * @param {string} [customTrackID=""]
     * @param {any | undefined} [data=undefine]
     * @memberof HybridMessage
     */
    static log(
      name,
      webAppID = '',
      applicationName = '',
      entityNumber = '',
      contractNumber = '',
      userGuid = '',
      customerGuid = '',
      customTrackID = '',
      data = undefined,
    ) {
      const log = new Log(
        name,
        webAppID,
        applicationName,
        entityNumber,
        contractNumber,
        userGuid,
        customerGuid,
        customTrackID,
        data,
      )
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.log.postMessage(JSON.stringify(log))
      } else if (AppChecker.isAndroidApp()) {
        window.Android.log(JSON.stringify(log))
      }
    }

    /**
     *
     *
     * @static
     * @description Sends an error to the app for displaying to the user
     * @param {string} title
     * @param {string} message
     * @memberof HybridMessage
     */
    static displayError(title, message) {
      const errorMessage = new ErrorMessage(title, message)
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.displayError.postMessage(JSON.stringify(errorMessage))
      } else if (AppChecker.isAndroidApp()) {
        window.Android.displayError(JSON.stringify(errorMessage))
      }
    }

    /**
     *
     *
     * @static
     * @description Requests device details from the app
     * @memberof HybridMessage
     */
    static getDeviceDetails() {
      if (AppChecker.isIOSApp()) {
        window.webkit.messageHandlers.getDeviceDetails.postMessage(null)
      } else if (AppChecker.isAndroidApp()) {
        window.Android.getDeviceDetails()
      }
    }

    /**
     *
     *
     * @static
     * @description Requests device details from the app
     * @memberof HybridMessage
     */
    static getDeviceDetailsCallback(callback) {
      if (AppChecker.isIOSApp()) {
        const promise = window.webkit.messageHandlers.getDeviceDetailsCallback.postMessage(null)
        promise.then((json) => {
          callback(JSON.parse(HybridMessage.replaceEscapes(json)))
        })
      } else if (AppChecker.isAndroidApp()) {
        window.Android.getDeviceDetailsCallback('(' + callback.toString() + ')')
      }
    }

    /**
     *
     *
     * @static
     * @description Sends the device details to the web
     * @param {string} json
     * @memberof HybridMessage
     */
    static sendDeviceDetails(json) {
      const didGetDeviceDetails = new CustomEvent(HybridMessage.Event.DidGetDeviceDetails, {
        detail: JSON.parse(HybridMessage.replaceEscapes(json)),
      })
      dispatchEvent(didGetDeviceDetails)
    }

    /**
     *
     *
     * @static
     * @description Determines whether the current host is in a hybrid app.
     * @returns {boolean}
     * @memberof HybridMessage
     */
    static isInHybridApp() {
      return (AppChecker.isAndroidApp() || AppChecker.isIOSApp()) && AppChecker.isUHaulEnterprise()
    }

    /**
     *
     *
     * @static
     * @description Returns the semantic version string of the hybrid library.
     * @returns {string}
     * @memberof HybridMessage
     */
    static getVersion() {
      return '1.3.0'
    }

    // Utility function
    static replaceEscapes(str) {
      return str.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t')
    }
  }
  /**
   *
   *
   * @static
   * @type {{
   *          DidReceiveMessage: string,
   *          SendMessageToAppFailed: string,
   *          DidGetDeviceDetails: string,
   *       }}
   * @memberof HybridMessage
   */
  HybridMessage.Event = {
    DidReceiveMessage: 'HybridMessageEventDidReceiveMessage',
    SendMessageToAppFailed: 'HybridMessageEventSendMessageToAppFailed',
    DidGetDeviceDetails: 'HybridMessageEventDidGetDeviceDetails',
  }
  HybridMessageModule.HybridMessage = HybridMessage
  window.HybridMessage = HybridMessage
  // Utility Classes
  class AppChecker {
    /*
           ====Native Application Checking Mechanism====
        */
    // isIOSApp, isAndroidApp, isApp, isMobile, isDesktop.
    // For iOS webView check
    static isIOSApp() {
      return window.webkit != null
    }

    // For android webView check
    static isAndroidApp() {
      return window.Android != null
    }

    // An app on iOS or Android
    static isApp() {
      return this.isIOSApp() || this.isAndroidApp()
    }

    // Enterprise app identified by the custom user-agent
    static isUHaulEnterprise() {
      return AppChecker.userAgent().isUHaulEnterprise
    }

    static isMobile() {
      let isMobile = false // initiate as false
      // device detection
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          navigator.userAgent.substr(0, 4),
        )
      ) {
        isMobile = true
      }
      return isMobile
    }

    static isDesktop() {
      let isDesktop = true // initiate as false
      // device detection
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          navigator.userAgent.substr(0, 4),
        )
      ) {
        isDesktop = false
      }
      return isDesktop
    }

    // This function returns the platform information as a string
    static platform() {
      let platform = ''
      let isMobile = false // initiate as false
      // device detection
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
          navigator.userAgent,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          navigator.userAgent.substr(0, 4),
        )
      ) {
        isMobile = true
      }
      if (isMobile) {
        if (window.Android != null) {
          platform = 'Android Native'
        } else if (window.webkit != null) {
          platform = 'IOS Native'
        } else {
          platform = 'Mobile Web'
        }
      } else {
        platform = 'Desktop Web'
      }
      return platform
    }

    static userAgent() {
      const userAgent = {
        isAndroid: /Android/.test(navigator.userAgent),
        isCordova: !!window.cordova,
        isEdge: /Edge/.test(navigator.userAgent),
        isFirefox: /Firefox/.test(navigator.userAgent),
        isChrome: /Google Inc/.test(navigator.vendor),
        isChromeIOS: /CriOS/.test(navigator.userAgent),
        isChromiumBased: !!window.chrome && !/Edge/.test(navigator.userAgent),
        isIE: /Trident/.test(navigator.userAgent),
        isIOS: /(iPhone|iPad|iPod)/.test(navigator.platform),
        isWebkit: window.webkit != null,
        isOpera: /OPR/.test(navigator.userAgent),
        isSafari: /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent),
        isUHaulEnterprise: /UHaul\/Enterprise/.test(navigator.userAgent),
      }
      return userAgent
    }
  }
  window.HybridMessage.AppChecker = AppChecker
  /**
   *
   *
   * @export
   * @class Message
   */
  class Message {
    /**
     * Creates an instance of Message.
     * @param {string} name -
     * @param {any} body -
     * @param {any} webAppID -
     * @memberof Message
     */
    constructor(name, body, webAppID) {
      this.name = name
      this.body = body
      this.webAppID = webAppID
    }
  }
  HybridMessageModule.Message = Message
  /**
   *
   *
   * @export
   * @class ErrorMessage
   */
  class ErrorMessage {
    /**
     * Creates an instance of ErrorMessage.
     * @param {string} title -
     * @param {string} message -
     * @memberof ErrorMessage
     */
    constructor(title, message) {
      this.title = title
      this.message = message
    }
  }
  HybridMessageModule.ErrorMessage = ErrorMessage
  /**
   *
   *
   * @export
   * @class Log
   */
  class Log {
    /**
     * Creates an instance of Message.
     * @param {string} name -
     * @param {string} webAppID -
     * @param {string} applicationName -
     * @param {string} entityNumber -
     * @param {string} contractNumber -
     * @param {string} userGuid -
     * @param {string} customerGuid -
     * @param {string} customTrackID -
     * @param {any} data -
     * @memberof Log
     */
    constructor(
      name,
      webAppID,
      applicationName,
      entityNumber,
      contractNumber,
      userGuid,
      customerGuid,
      customTrackID,
      data,
    ) {
      this.name = name
      this.webAppID = webAppID
      this.applicationName = applicationName
      this.entityNumber = entityNumber
      this.contractNumber = contractNumber
      this.userGuid = userGuid
      this.customerGuid = customerGuid
      this.customTrackID = customTrackID
      this.data = JSON.stringify(data)
    }
  }
  HybridMessageModule.Log = Log
})(HybridMessageModule || (HybridMessageModule = {}))
