var MP = {
  Version: '3.1.1.0',
  SrcLang: 'en',
  UrlLang: 'mp_js_current_lang',
  SrcUrl: decodeURIComponent('mp_js_orgin_url'),
  oSite: decodeURIComponent('mp_js_origin_baseUrl'),
  tSite: decodeURIComponent('mp_js_translated_baseUrl'),

  init: function () {
    if (MP.oSite.indexOf('p_js_') == 1) {
      MP.SrcUrl = window.top.document.location.href
      MP.oSite = MP.tSite = window.top.document.location.host
      MP.UrlLang = MP.SrcLang
    }
  },
  switchLanguage: function (url, pref, sync) {
    var sync = sync
    var oSite = MP.oSite.replace('http://', '').replace('https://', '').replace(/\/?$/, '')
    var tSite = MP.tSite.replace('http://', '').replace('https://', '').replace(/\/?$/, '')
    url = url.replace('http://', '').replace('https://', '').replace(/\/?$/, '')
    if (sync && typeof MpStorage !== 'undefined' && typeof MpStorage.updatePref !== 'undefined') {
      if (pref) {
        var lang = pref.substring(0, 2)
        pref = lang == MP.UrlLang ? MP.SrcLang + pref.substring(2) : pref
      }
      MpStorage.updatePref(url, pref)
    }
    setTimeout(function () {
      var script = document.createElement('SCRIPT')
      if (url == oSite) {
        script.src =
          location.protocol + '//' + tSite + '?1023749634;' + encodeURIComponent(location.href)
      } else {
        script.src = location.protocol + '//' + url + '?1023749632;' + encodeURIComponent(MP.SrcUrl)
      }
      var target = document.getElementsByTagName('script')[0]
      target.parentNode.insertBefore(script, target)
    }, 500)
    return false
  },
  switchToLang: function (url) {
    if (window.top.location.href == url) {
      if (typeof MpStorage !== 'undefined' && typeof MpStorage.updatePref !== 'undefined')
        MpStorage.updatePref(MP.oSite, MP.SrcLang)
    } else {
      window.top.location.href = url
    }
  },
}

MP.UrlLang = 'mp_js_current_lang'
MP.SrcUrl = decodeURIComponent('mp_js_orgin_url')
MP.oSite = decodeURIComponent('mp_js_origin_baseUrl')
MP.tSite = decodeURIComponent('mp_js_translated_baseUrl')
MP.init()

var mp_langLink = function () {
  var langlinks = document.querySelectorAll('.langLink')
  for (var i = 0; i < langlinks.length; i++) {
    langlinks.item(i).onclick = function () {
      MP.init()
      var lang = this.getAttribute('data-lang')
      var url = this.getAttribute('data-href')
      var tSite = MP.tSite.replace(/(https?:\/\/|\/?$)/g, '')
      url = url.replace(/(https?:\/\/|\/?$)/g, '')
      MP.switchLanguage(tSite.search(url) != -1 ? MP.oSite : url, lang, true)
      return false
    }
  }
}
if (window.addEventListener) {
  window.addEventListener('load', mp_langLink, false)
} else if (window.attachEvent) {
  window.attachEvent('onload', mp_langLink)
}
