import dayjs from 'dayjs'

import { getBrowserData, getPlatform, isInHybridApp, isMobile } from '@lib/hybrid-checker'
import { getFromSession, removeFromSession, saveToSession } from '@util/localStorage'
import { HybridMessageEvent } from '@util/constants'
import { getPostRequest } from '@util/request'
import '@lib/hybrid-message'

interface PastEvent {
  name: string
  args: unknown
}
type CallbackFunction = (...args: unknown[]) => unknown

const NATIVE_CLASS = 'native'
const NATIVE_URL = '/Native/SetNativeFlag'
const SESSION_KEY = 'NativeFlag'
// In Minutes
const SESSION_TIMEOUT = 20
const ACCEPTED_CONTEXTS = ['SelfDispatch', 'SelfReturn', 'CustomerDispatch', 'Demo']

;(function (UhaulNative) {
  const _self = UhaulNative
  const doc = document.documentElement
  const eventHandlers: Record<string, CallbackFunction[]> = {}
  const pastEvents: PastEvent[] = []
  let state: string

  async function setServerSideNativeSessionFlag(flag: boolean): Promise<void> {
    let shouldSendRequest = ACCEPTED_CONTEXTS.includes(CONTROLLER_NAME)

    if (flag) {
      const now = dayjs()
      const sessionFlag = getFromSession<string | undefined>(SESSION_KEY)

      // Session Flag exits
      if (sessionFlag) {
        const sessionTime = dayjs(sessionFlag)

        // If Adding 20 minutes to the saved flag is in the future don't send the request
        if (dayjs(sessionTime).add(SESSION_TIMEOUT, 'minutes').isAfter(now)) {
          shouldSendRequest = false
        }
      }

      saveToSession(SESSION_KEY, now.format())
    } else {
      removeFromSession(SESSION_KEY)
    }

    if (shouldSendRequest && flag) {
      // Add the listener and make call to hybrid library
      addEventListener()
      HybridMessage.getDeviceDetails()

      // If after 1 second with no response fallback without app version
      setTimeout(() => {
        if (state === 'listening') {
          removeEventListener()
          setNativeFlagFallback(flag)
        }
      }, 1000)
    } else if (shouldSendRequest) {
      setNativeFlagFallback(false)
    }
  }

  async function setNativeFlag(event: CustomEvent<HybridDeviceDetails>): Promise<void> {
    state = ''
    const { detail: appDetails } = event
    const browserDetails = getBrowserData()
    const payloadDetails = {
      ...browserDetails,
      os: {
        name: appDetails?.deviceOS ?? browserDetails.os?.name,
        version: appDetails?.deviceOSVersion ?? browserDetails.os?.version,
        versionName: browserDetails.os?.versionName,
      },
    }
    const request = getPostRequest()

    await request(NATIVE_URL, {
      UhaulNativeFlag: true,
      AppPlatform: getPlatform(),
      AppVersion: appDetails.appVersion,
      Details: {
        ...payloadDetails,
        device: {
          model: appDetails.deviceModel,
          type: appDetails.deviceType,
          connection: appDetails.connectionType,
        },
      },
    })
  }

  async function setNativeFlagFallback(flag: boolean): Promise<void> {
    state = ''
    const request = getPostRequest()

    await request(NATIVE_URL, {
      UhaulNativeFlag: flag,
      AppPlatform: getPlatform(),
      Details: getBrowserData(),
    })
  }

  _self.SetNative = function () {
    doc.classList.add(NATIVE_CLASS)

    setServerSideNativeSessionFlag(true)
  }

  _self.ClearNative = function () {
    doc.classList.remove(NATIVE_CLASS)

    setServerSideNativeSessionFlag(false)
  }

  /* Call custom event */
  _self.On = function (eventName: string, handler: CallbackFunction): void {
    if (handler && typeof handler === 'function') {
      const handlers = getEventHandlers(eventName)
      handlers.push(handler)

      /* Call the handler for any past events */
      pastEvents.forEach((pEvent) => {
        if (pEvent.name === eventName) {
          handler(pEvent.args)
        }
      })
    }
  }

  /* Register and record custom event */
  _self.Emit = function (eventName: string, args: unknown): void {
    const handlers = getEventHandlers(eventName)
    handlers.forEach((h) => {
      h(args)
    })
    pastEvents.push({ name: eventName, args })
  }

  if ((isMobile() && isInHybridApp()) || window.location.href.includes('native=true')) {
    _self.SetNative()
  } else if (window.location.href.includes('native=false')) {
    _self.ClearNative()
  }

  function getEventHandlers(name: string): ((...args: unknown[]) => unknown)[] {
    eventHandlers[name] = eventHandlers[name] || []
    return eventHandlers[name]
  }

  function addEventListener(): void {
    state = 'listening'
    window.addEventListener(HybridMessageEvent.DidGetDeviceDetails, setNativeFlag)
  }

  function removeEventListener(): void {
    window.removeEventListener(HybridMessageEvent.DidGetDeviceDetails, setNativeFlag)
  }
})((window.UhaulNative = window.UhaulNative || {}))
